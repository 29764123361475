import React, { useState } from "react";
import cuid from "cuid";
import { Link, navigate } from "gatsby";
import PropTypes from "prop-types";
import { useTheme } from "@material-ui/core/styles";
import {
  Box,
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Drawer,
  useMediaQuery,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import MenuIcon from "@material-ui/icons/Menu";
import { rem } from "polished";

import { useSiteMetadata } from "../../hooks/use-site-metadata";

import { Logo } from "../Logo";

import {
  StyledHeader,
  StyledList,
  StyledListItem,
  StyledListItemText,
} from "./Header.styled";

const Header = () => {
  const [state, setState] = useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  const { nav } = useSiteMetadata();

  const toggleDrawer = (anchor, open) => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState({ ...state, [anchor]: open });
  };

  const handleMobileNavigation = (href) => {
    navigate(href);
    setState({ ...state, ["right"]: false });
  };

  return (
    <>
      <StyledHeader>
        <AppBar position="static" color="primary">
          <Toolbar>
            <Box
              display="flex"
              justifyContent="space-between"
              sx={{ width: `100%` }}
            >
              <Box display={isSmall ? `flex` : `block`}>
                {isSmall && (
                  <>
                    <IconButton
                      edge="start"
                      color="inherit"
                      aria-label="menu"
                      onClick={toggleDrawer("right", true)}
                    >
                      <MenuIcon />
                    </IconButton>
                  </>
                )}
                <Logo />
              </Box>
              {!isSmall && (
                <Box
                  display="flex"
                  flexDirection="column"
                  justifyContent="center"
                >
                  <Box>
                    {nav.map((el) => {
                      return (
                        <Button
                          color="secondary"
                          component={Link}
                          to={el.route}
                          activeStyle={{ color: "white" }}
                        >
                          {el.name}
                        </Button>
                      );
                    })}
                  </Box>
                </Box>
              )}
            </Box>
          </Toolbar>
        </AppBar>
      </StyledHeader>
      <Drawer
        PaperProps={{ style: { backgroundColor: `#252d41` } }}
        anchor="right"
        open={state["right"]}
        onClose={toggleDrawer("right", false)}
      >
        <Box
          height={`${rem(126)}`}
          display="flex"
          flexDirection="column"
          justifyContent="center"
        >
          <Box display="flex" justifyContent="flex-end">
            <IconButton
              aria-label="close"
              onClick={toggleDrawer("right", false)}
            >
              <CloseIcon style={{ color: `white` }} />
            </IconButton>
          </Box>
        </Box>
        <StyledList>
          {nav.map((el) => (
            <StyledListItem
              key={cuid()}
              component={Button}
              onClick={() => handleMobileNavigation(el.route)}
            >
              <StyledListItemText primary={el.name} />
            </StyledListItem>
          ))}
        </StyledList>
      </Drawer>
    </>
  );
};

Header.propTypes = {
  children: PropTypes.node,
};

export default Header;

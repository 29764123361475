import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { Box, Typography, IconButton, useMediaQuery } from "@material-ui/core";
import FacebookIcon from "@material-ui/icons/Facebook";

import { StyledFooter } from "./Footer.styled";

const Footer = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <StyledFooter py={isSmall ? 2 : 13} px={isSmall ? 2 : "initial"}>
      <Box display={isSmall ? `block` : `flex`} justifyContent="space-evenly">
        <Box>
          <Typography color="textSecondary">
            412 Kiowa Street Leavenworth, KS 66048{" "}
          </Typography>
        </Box>
        <Box>
          <Typography color="textSecondary">
            Copyright Richard Allen Cultural Center and Museum. All rights
            reserved.
          </Typography>
        </Box>
        <Box>
          <IconButton
            onClick={(event) =>
              (window.location.href =
                "https://www.facebook.com/pages/category/History-Museum/Richard-Allen-Cultural-Center-Museum-Inc-114837371946955/")
            }
            aria-label="facebook"
            style={{ color: `white` }}
          >
            <FacebookIcon />
          </IconButton>
        </Box>
      </Box>
    </StyledFooter>
  );
};

export default Footer;

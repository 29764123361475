import { styled, Box, List, ListItem, ListItemText } from "@material-ui/core";
import { rem } from "polished";

const StyledHeader = styled(Box)(({ theme }) => ({
  padding: `${rem(theme.spacing(0))}`,
}));

const StyledList = styled(List)(() => ({
  backgroundColor: `#252D41`,
}));
const StyledListItem = styled(ListItem)(() => ({
  display: `block`,
  paddingTop: `${rem(10)}`,
  paddingBottom: `${rem(10)}`,
  paddingLeft: `${rem(34)}`,
  paddingRight: `${rem(86)}`,
}));

const StyledListItemText = styled(ListItemText)(({ buttonIsActive }) => ({
  color: `#fff`,
  borderBottom: `${buttonIsActive ? "2px solid white" : "initial"}`,
}));

export { StyledHeader, StyledList, StyledListItem, StyledListItemText };

// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../../../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-events-js": () => import("./../../../src/pages/events.js" /* webpackChunkName: "component---src-pages-events-js" */),
  "component---src-pages-geneology-js": () => import("./../../../src/pages/geneology.js" /* webpackChunkName: "component---src-pages-geneology-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-join-support-js": () => import("./../../../src/pages/join-support.js" /* webpackChunkName: "component---src-pages-join-support-js" */),
  "component---src-pages-plan-your-visit-js": () => import("./../../../src/pages/plan-your-visit.js" /* webpackChunkName: "component---src-pages-plan-your-visit-js" */),
  "component---src-pages-tutoring-js": () => import("./../../../src/pages/tutoring.js" /* webpackChunkName: "component---src-pages-tutoring-js" */)
}


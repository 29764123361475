import { createTheme } from "@material-ui/core/styles";

const theme = createTheme({
  palette: {
    common: { black: "#000", white: "#fff" },
    background: { paper: "#fff", default: "#EFEFEF" },
    primary: {
      light: "rgba(44, 48, 69, 1)",
      main: "rgba(37, 40, 58, 1)",
      dark: "rgba(29, 32, 46, 1)",
      contrastText: "#fff",
    },
    secondary: {
      light: "rgba(95, 219, 168, 1)",
      main: "rgba(61, 200, 143, 1)",
      dark: "rgba(54, 178, 127, 1)",
      contrastText: "#fff",
    },
    error: {
      light: "#e57373",
      main: "#f44336",
      dark: "#d32f2f",
      contrastText: "#fff",
    },
    text: {
      primary: "rgba(0, 0, 0, 0.87)",
      secondary: "rgba(255,255,255)",
      disabled: "rgba(0, 0, 0, 0.38)",
      hint: "rgba(0, 0, 0, 0.38)",
    },
  },
  typography: {
    fontFamily: `'Yeseva One','Open Sans', serif, sans-serif`,
    h1: {
      fontFamily: `'Yeseva One', serif`,
    },
    h2: {
      fontFamily: `'Yeseva One', serif`,
    },
    h3: {
      fontFamily: `'Yeseva One', serif`,
    },
    h4: {
      fontFamily: `'Yeseva One', serif`,
    },
    h5: {
      fontFamily: `'Yeseva One', serif`,
    },
    h6: {
      fontFamily: `'Yeseva One', serif`,
    },
    subtitle1: {
      fontFamily: `'Open Sans', sans-serif`,
    },
    subtitle2: {
      fontFamily: `'Open Sans', sans-serif`,
    },
    body1: {
      fontFamily: `'Open Sans', sans-serif`,
    },
    body2: {
      fontFamily: `'Open Sans', sans-serif`,
    },
    button: {
      fontFamily: `'Open Sans', sans-serif`,
    },
    caption: {
      fontFamily: `'Open Sans', sans-serif`,
    },
    overline: {
      fontFamily: `'Open Sans', sans-serif`,
    },
  },
});

export default theme;

import { useStaticQuery, graphql } from "gatsby";

export const useSiteMetadata = () => {
  const { site } = useStaticQuery(
    graphql`
      query SiteMetaData {
        site {
          siteMetadata {
            nav {
              name
              route
            }
          }
        }
      }
    `
  );
  return site.siteMetadata;
};

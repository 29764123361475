import React from "react";
import { useTheme } from "@material-ui/core/styles";
import { useMediaQuery } from "@material-ui/core";

import { StyledLogoName, StyledLogoSubName } from "./Logo.styled";

const Logo = () => {
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  return (
    <>
      <StyledLogoName variant="h3">Richard Allen</StyledLogoName>
      {!isSmall && (
        <StyledLogoSubName>Cultural Center & Museum</StyledLogoSubName>
      )}
    </>
  );
};

export default Logo;

import { styled, Typography } from "@material-ui/core";
import { rem } from "polished";

const StyledLogoName = styled(Typography)(({ theme }) => ({
  fontSize: `${rem(32)}`,
}));

const StyledLogoSubName = styled(Typography)({
  fontSize: `${rem(18)}`,
});

export { StyledLogoName, StyledLogoSubName };
